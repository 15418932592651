.template-summary_view{

  #content{
    
    .tileFooter {
      display: none;
    }
  
    .documentByLine {
      display: none;
    }
    
    h1.documentFirstHeading{
      font-family: $title-font-stack;
      font-size: 12px;
      text-align: center;
      font-weight: normal;
    }
  
    article.tileItem{
  
      margin-bottom: 48px;
  
      .tileHeadline {
        text-align: center;
        margin-bottom: 0px;
        a{
          font-family: $default-font-stack-roman ;
          font-size: 16px;
          line-height: 25px;
          font-weight: normal;
          &:hover{
            text-decoration: underline;

          }
        }
      }
  
      .tileBody {
        text-align: center;
  
        span{
          font-family: $default-font-stack;
          font-size : 16px;
          line-height: 22px;
          font-weight: normal;
        }
      }
  
  
    }

  }

}