// Variables

$font-size-base   : 1rem;
$line-height-base : 1.15;


$default-font-stack: 'Helvetica Neue LT Std 45 Light',
Helvetica,
sans-serif;
$title-font-stack: 'ABChanel Corpo_Regular',
sans-serif;
$navigation-font-stack: 'ABChanel Corpo_Regular',
sans-serif;
$default-font-stack-bold: 'Helvetica Neue Bold',
Helvetica,
sans-serif;
$default-font-stack-bold-chanel: 'ABChanel Corpo_Semi Bold',
Helvetica,
sans-serif;
$default-font-stack-light-chanel: 'ABChanel Corpo_Light',
Helvetica,
sans-serif;
$default-font-stack-regular: 'Helvetica Neue LT Std 65 Medium',
Helvetica,
sans-serif;
$default-font-stack-roman: 'Helvetica Neue LT Std 55 Roman',
Helvetica,
sans-serif;
$default-font-stack-bolder: 'Helvetica Neue LT Std 75 Bold',
Helvetica,
sans-serif;
//Channel Colors

//Primary
$channel-primary  : #000;
$channel-secondary: #CCC;
$channel-light    : #FFFFFF;

// Overides

$breadcrumb-padding-x: 0;

// Colors
$primary  : $channel-primary;
$secondary: $channel-secondary;

//Links
$link-color      : #000;
$link-decoration : none;
$link-hover-color: #000;
$link-hover-decoration: underline;