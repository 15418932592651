.template-login-help:not(.plone-modal-open) {

    .navbar {
        display: none;
    }

    #content-block {
        justify-content: center;

        @media (min-width: 992px) {
            #content {
                max-width: 90% !important;
            }
        }
    }

    #content {
        margin-top: 80px;
    }

    #content .row {
        margin-bottom: 80px;

        &>div:first-child:not(:last-child) {
            border-right: 1px solid #D3D3D3;
        }

        &>div {
            padding: 0 60px;
        }
    }

    .subtitle {
        font-family   : $title-font-stack;
        font-size     : 12px;
        text-transform: uppercase;
        margin-bottom : 10px;
    }

    .example {
        color      : #666666;
        font-weight: 400;
        font-style : italic;
        font-size  : 14px;
    }

    form {
        label {
            margin-top: 40px;
        }

        input[type="text"] {
            background-color: #F5F5F5;
            border          : none;
            box-shadow      : none;
            border-radius   : 0;
            margin-top      : 10px;
        }

        [type="submit"] {
            border          : 1px solid transparent;
            border-radius   : 0;
            font-family     : $title-font-stack;
            font-size       : 12px;
            box-shadow      : none;
            text-shadow     : none;
            padding         : 10px 20px;
            font-weight     : normal;
            background-color: $channel-primary;
            color           : $channel-light;
            border          : 1px solid $channel-primary;
        }

        .field.error {
            margin-top: 20px;

            label {
                margin-top: 0;
            }
        }
    }

    .trouble,
    .footer {
        a {
            font-family    : $default-font-stack-regular;
            text-decoration: underline;
        }
    }

    .trouble {
        a {
            margin-top: 60px;
            display   : inline-block;
        }
    }

    #content-alert .alert {
        padding-bottom: 0;
        margin-bottom : -40px;

        .portalMessage {
            margin-bottom: 0;
        }
    }
}