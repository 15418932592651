// toolbar

#edit-zone {
    z-index : 10000;
    position: relative;
}

// TEMPORARY: use a specific class

.section-fragrance #video-metadata-container .validation {
    display: none;
}

.section-fragrance #video-comments-container {
    display: none;
}

// CONTENT RULES
#content {
    #configure-conditions,
    #configure-actions {
        width  : auto;
        padding: 0;
        float  : none;
    }
}

@media (max-width: 767px) {
    #video-metadata-container {
        padding: 10px 0;
    }

    #video-comments-container {
        padding: 0;
    }
}

// MOSAIC

.mosaic-grid-row-red {
  background: #c4183c;
  padding: 1ex 1em;
  color: #2c2c2c;

  & + .mosaic-grid-row {
    padding-top: 1ex;
  }

  & + .mosaic-grid-row-red {
    padding-top: 0;
  }
}
