$browser-context: $font-size-base;

@function rem($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

.gotop {
  position       : fixed;
  padding        : 10px 28px;
  z-index        : 100;
  left           : 45%;
  bottom         : 0;
  font-size      : rem(18px);
  border-radius  : 36px 36px 0 0;
  background     : rgba(0, 0, 0, 0.8);
  color          : #fff;
  text-align     : center;
  text-decoration: none;
  box-shadow     : 0 3px 10px 3px rgba(255, 255, 255, 0.4);
  display        : none;

  &:hover {
    color          : #fff;
    background     : rgba(0, 0, 0, 1);
    text-decoration: none;
    text-shadow    : 0 0 5px #fff;
  }
}

.breadcrumb {
  background-color: transparent;
  font-size       : rem(12);
  padding-top     : rem(26px);
  padding-bottom  : rem(8px);
  margin-bottom   : 0;
  text-transform  : uppercase;

  @media (max-width: 767px) {
    display: none;
  }
}

main {
  position           : relative;
  display            : flex;
  flex-direction     : row;
  flex               : 1;
  background-size    : cover;
  background-repeat  : no-repeat;
  background-position: center;

  h1,
  h1.documentFirstHeading {
    font-family   : $title-font-stack;
    font-size     : 14px;
    margin-top    : 13px;
    padding-bottom: 13px;
  }

  #main-content {
    width : 80%;
    margin: 0 auto;

    #content-block {
      display: flex;

      #content {
        flex     : 1;
        max-width: 100%;
      }

      @media (max-width: 767px) {
        flex-direction: column;

        #content-video.aside-right {
          max-width: 100%;

          .section.clearfix {
            padding-bottom: 0;
          }
        }

        #portal-column-two {
          padding: 0;
          width  : 100%;
        }
      }
    }
  }

  .documentDescription {
    font-size  : 16px;
    color      : $channel-primary;
    font-weight: normal;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.portaltype-document,
.portaltype-troubleticket,
.portaltype-collection,
.portaltype-file,
.portaltype-folder {
  #content-block {
    margin-top: 30px;
  }
}

h1 a:focus,
h1 a:hover,
h2 a:focus,
h2 a:hover,
h3 a:focus,
h3 a:hover {
  border-bottom: none;
}

a:focus,
a:hover {
  color: #000000;
}

p {
  letter-spacing: 0.025rem;
}

#searchform {
  .input-group {
    display  : flex;
    width    : 100%;
    max-width: none;

    .searchPage.form-control {
      float: none;
      flex : 1;

      &+.input-group-btn {
        width                     : auto;
        border                    : 1px solid #000000;
        border-bottom-right-radius: 4px;
        border-top-right-radius   : 4px;
        height                    : 34px;
      }
    }
  }

  #search-results-bar #results-count {
    float  : none;
    margin : 1rem 0;
    display: inline-block;
  }

  .actionMenu {
    float        : none;
    position     : relative;
    margin-bottom: 2rem;

    .actionMenuHeader {
      position: relative;

      #search-filter-toggle {
        width     : 100%;
        margin-top: 0.5rem;
      }
    }

    .actionMenuContent {
      top: 100%;
    }
  }

  #search-results {
    ol {
      padding: 0;

      li {
        text-align : left;
        margin-left: 1rem;

        .croppedDescription {
          margin-top: 0.5rem;
        }
      }
    }
  }
}

// Validation et tag-list

.tag-list:not(:empty) {
  margin    : 0.8rem 0 0 0;
  text-align: left;

  li {
    font-family: $default-font-stack;
    font-size  : rem(12);
    display    : inline-block;

    &:before {
      content: '#';
    }

    a {
      font-family     : $default-font-stack;
      font-size       : rem(12);
      background-color: transparent;
      text-transform  : uppercase;
      padding         : 0;
      margin-left     : -2px;
    }
  }
}

.tag-list:empty {
  padding: 0;
  margin : 0;
}

body.portaltype-video .title-video {
    font-size  : rem(20) !important;
    line-height: rem(22) !important;
  }

.title-parent,
.title-video {
  font-family  : $default-font-stack-regular;
  text-align   : left;
  margin-bottom: 0.8rem;
  font-size    : rem(30);
  line-height  : rem(33);
}


.description-video {
  font-family   : $default-font-stack-roman;
  font-size     : rem(20);
  margin-bottom : rem(20);
  line-height   : rem(22);
  text-align    : left;
  letter-spacing: 0.025rem;
}

.aside {
  text-align: left;
}

.portletStaticText.omit-border .portletContent p a,
.download-button {
  display    : flex;
  align-items: center;
  font-family: $title-font-stack;
  font-size  : rem(12);
  padding-top: 20px;
  text-align: start;

  &:focus {
    outline: none;
  }

  &:before {
    content            : '';
    width              : 30px;
    height             : 30px;
    display            : inline-flex;
    margin-right       : 10px;
    background         : url(../img/download.svg) no-repeat;
    background-size    : 30px;
    background-color   : black;
    background-position: center;
    flex-shrink        : 0;
    flex-grow          : 0;
    flex-basis         : 30px;
  }

  &:hover {
    color: #000;

    &:before {
      opacity: 0.9;
    }
  }
}

.validation {
  align-items: flex-end;

  .custom-checbox {
    border    : 1px solid #000;
    appearance: none;
  }

  div {
    align-self: flex-end;
  }

}

.circle-state {
  background-color: #cccccc;
  border-radius   : 50%;
  width           : rem(100);
  height          : rem(100);
  font-family     : $title-font-stack;
  font-size       : rem(10);
  display         : inline-flex;
  align-items     : center;
  justify-content : center;

  &.pending {
    background-color: #cccccc;
    color           : #fff;
  }

  &.rejected {
    background-color: #8E000D;
    color           : #fff;
  }

  &.validated {
    background-color: #2B5A36;
    color           : #fff
  }
}

.portaltype-plone-site {
  .portalMessage {
    display      : block;
    border-radius: 0;
    box-shadow   : none;
    font-family  : $default-font-stack;
    line-height  : normal;
    padding      : 10px;
    text-shadow  : none;

    strong {
      font-family: $default-font-stack-regular;
    }

    &.info {
      background-color: #F1F1F1;
      border          : none;

      &>strong::before {
        content            : "";
        background         : url(../img/info.svg) no-repeat;
        background-size    : 20px;
        background-position: center;
        display            : inline-flex;
        border-radius      : 50%;
        width              : 20px;
        height             : 20px;
        flex-shrink        : 0;
        flex-grow          : 0;
        flex-basis         : 20px;
        margin-right       : 0.5rem;
        top                : 4px;
      }
    }
  }
}

// Checkbox

.custom-control label {
  cursor: pointer;
}

.checkbox-button {
  cursor: pointer;
}

input[type=checkbox] {
  box-sizing: border-box;
  padding   : 0;
}

input {
  font-size       : 1rem;
  line-height     : 1.5;
  padding         : 11px 23px;
  border          : 1px solid rgba(0, 0, 0, .15);
  border-radius   : 0;
  outline         : 0;
  background-color: transparent;

  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    border    : 1px solid rgba(0, 0, 0, .15) !important;
  }
}

.custom-checkbox-square {
  opacity : 0;
  position: absolute;
}

.custom-checkbox-square__control {
  position        : relative;
  display         : inline-block;
  width           : 22px;
  height          : 22px;
  margin-right    : 5px;
  vertical-align  : middle;
  background-color: inherit;
  color           : #000;
  border          : 1px solid #C1C1C1;
}

.custom-checkbox-square:checked+.custom-checkbox-square__control:after {
  content         : "";
  display         : block;
  position        : absolute;
  top             : 5px;
  left            : 5px;
  width           : 10px;
  height          : 10px;
  background-color: #000;
}

.custom-checkbox-square:checked+.custom-checkbox-square__control {
  border-color: #000;
}

.custom-checkbox-square__control {
  transform: scale(0.75);
}

.custom-checkbox-square__label {
  font-family: $title-font-stack;
  font-size  : rem(12);
  font-weight: normal;
}

// BO

// Alerts

.template-edit {
  #content {
    .documentFirstHeading {
      font-family: $default-font-stack;
      font-size  : rem(18px);
    }

    #content-core {
      text-align: left;

      #form {
        margin: 0;
      }
    }
  }
}

b,
strong {
  font-weight: bold;
}

#content-alert .alert {
  top    : 10px;
  padding: .75rem 0;
}

.navbar-toggler {
  display: none;
}

.spinner {
  display: none;
}

// Get help{
.template-login-help {
  #breadcrumb {
    display: none;
  }
}

// Login

.template-login {

  #breadcrumb {
    display: none;
  }

  #login-form {
    margin   : 80px auto;
    max-width: 380px;
  }

  .footer {
    margin-top: 4rem;

    a {
      font-family    : $default-font-stack-regular;
      text-decoration: underline;
      font-size      : 14px;
    }
  }
}

.formControls {
  border-top: none;
  background: transparent;
  padding   : 12px;
  box-shadow: none;
}

.list-unstyled {
  list-style-type: none;
  padding-left   : 0;
}

a:focus,
a:active,
a:hover,
a:visited {
  outline       : none !important;
  outline-offset: 0 !important;
}

// Modales
.modal-content {
  border-radius: 0;

  .modal-body {
    padding: 3rem;
  }

  .close {
    font-size: 2.5rem;
    opacity  : .8;
    position : absolute;
    right    : 20px;
    top      : 20px;


    span {
      -webkit-text-stroke: 0.5px white;
      font-size          : 50px;
      font-family        : $default-font-stack;
    }

    &:hover,
    &:active {
      box-shadow: none;
      opacity   : 1;
    }
  }

  h4 {
    margin-bottom: 0.5rem;
    font-family  : $title-font-stack;
    font-size    : rem(16);
  }

  ul {
    margin-bottom: 1rem;

    li {
      line-height: rem(18);
      font-family: $default-font-stack;
      font-size  : rem(14);
      min-height : rem(20);
    }
  }
}

// Owl
.owl-prev,.owl-next {
  color: rgba(255, 255, 255, 0) !important;
}
.owl-theme {
  .owl-nav [class*='owl-'] {
    background-color: transparent;

    &:hover {
      background-color: red;
    }
  }
}

.owl-theme {
  .owl-nav {
    margin-top: 0px !important;

    .owl-prev,
    .owl-next {
      width           : 30px;
      height          : 100%;
      position        : absolute;
      top             : 0;
      margin          : 0px !important;
      display         : block !important;
      border          : 0px solid black;
      background-color: #fff !important;

      img {
        width   : 20px;
        height  : 20px;
        position: absolute;
        top     : calc(50% - 10px);
      }
    }

    .owl-prev {
      img {
        left: 0px;
      }
    }

    .owl-next {
      right: 0;

      img {
        right: 0px;
      }
    }
  }
}

.hide {
  display: none;
}

#login-form {
  span.required {
    display: none;
  }
}

#edit-zone nav>ul ul li.plone-toolbar-submenu-header>span,
#edit-zone nav>ul ul li.plonetoolbar-display-view.actionSeparator>span {
  display: none;
}

#login-form {

  .formControls {
    padding: 12px 0;
  }

  label {
    font-size  : 14px;
    font-family: $default-font-stack-regular;
    font-weight: normal;
  }

  #buttons-login {
    font-family: $title-font-stack;
    font-weight: normal;
    font-size  : 12px;
    min-width  : 100%;

    &:hover,
    &:active,
    &:focus {
      background-color: $channel-primary;
      color           : $channel-light;
      border          : 1px solid $channel-primary;
    }

    &:hover,
    &:focus,
    &:active {
      opacity: 0.7;
    }
  }

  #external-login {

    &,
    &:hover,
    &:focus,
    &:active {
      border          : 1px solid transparent;
      border-radius   : 0;
      font-family     : $title-font-stack;
      font-size       : 12px;
      box-shadow      : none;
      text-shadow     : none;
      padding         : 10px 20px;
      font-weight     : normal;
      background-color: $channel-primary;
      color           : $channel-light;
      border          : 1px solid $channel-primary;
      display         : block;
      margin-top      : 10px;
      min-width       : 100%;
      text-decoration : none;
    }

    &:hover,
    &:focus,
    &:active {
      opacity: 0.7;
    }
  }

  #login-or {
    margin-top: 10px;
    font-size : 14px;
  }
}

@media (max-width: 767px) {
  .validation {
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding        : 0 15px;

    div {
      padding   : 0;
      flex      : 1;
      width     : auto;
      align-self: center;
    }
  }

  .download-button {
    padding-top: 10px;
  }

  #content {
    #video-comments-container+div {
      padding: 0;
    }
  }

  #searchform {
    #searchResultsSort {
      max-height    : none;
      overflow      : auto;
      padding-bottom: 1rem;
      margin-bottom : 1rem;

      #sorting-options {
        display        : flex;
        flex-direction : column;
        justify-content: stretch;
        align-items    : stretch;

        a {
          margin: 0 0.5rem;
        }
      }
    }

    .input-group {
      flex-direction: column;

      .searchPage {
        margin-bottom: 0.5rem;
      }

      .input-group-btn {
        border-radius: 4px;

        .allowMultiSubmit.btn {
          width        : 100%;
          margin-left  : 0;
          border-radius: 4px;
        }
      }
    }
  }
}

body:not(.userrole-manager) {

  .state-private,
  .state-pending {
    color: #000 !important;
  }

  .header .dropdown-menu a:focus,
  .header .dropdown-menu a:hover {
    background-color: #000;
    color           : #fff !important;
  }
}

.documentAuthor,
.documentModified {
  display: none;
}

// Modales
.modal-open .modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-dialog {
  height         : 100%;
  margin         : 0 auto !important;
  display        : inline-flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;

  .modal-header {
    border : none;
    padding: 0;
  }

  .modal-content {
    border-radius: 0;
    border       : 1px solid rgba(0, 0, 0, .1);
    box-shadow   : 0px 2px 9px 0 rgba(0, 0, 0, .1);

    .modal-body {
      padding: 50px;
    }

    .close {
      font-size: 2.5rem;
      opacity  : .8;
      position : absolute;
      right    : 20px;
      top      : 25px;
      padding  : 0;
      margin   : 0;
      z-index  : 1;

      &::before {
        content            : "×";
        display            : block;
        line-height        : 25px;
        -webkit-text-stroke: 0.4px white;
        font-size          : 50px;
        font-family        : $default-font-stack;
      }

      &:hover,
      &:active {
        box-shadow: none;
        opacity   : 1;
      }


      span {
        display: none;
      }

      &:hover,
      &:active {
        box-shadow: none;
        opacity   : 1;
      }
    }

    h4 {
      margin-bottom: 0.5rem;
      font-family  : $title-font-stack;
      font-size    : rem(16);
    }

    ul {
      margin-bottom: 1rem;

      li {
        line-height: rem(18);
        font-family: $default-font-stack;
        font-size  : rem(14);
        min-height : rem(20);
      }
    }
  }
}

label .required::after {
  color      : #c4183c;
  content    : "•";
  font-size  : 20px;
  line-height: 0;
  position   : relative;
  top        : 3px;
  margin-left: -4px;
  font-family: $default-font-stack-roman;
}

.portaltype-document {

  .leadImage {
    display: none;
  }
}
