.template-user_experience #content {
    .header-content {
        display        : flex;
        align-items    : stretch;
        justify-content: center;
        border-bottom  : none;
        margin-bottom  : 0;
        flex-direction : column;
        padding-bottom : 20px;

        h1 {
            font-family   : $title-font-stack;
            font-size     : 12px;
            margin-top    : 13px;
            padding-bottom: 13px;
            border-bottom : 1px solid #cccccc;
        }

        .button {
            font-family     : $title-font-stack;
            font-size       : 12px;
            background-color: $primary;
            color           : $channel-light;
            padding         : 10px 20px;
            align-self      : center;
            margin-top      : 12px;
        }
    }

    .has-table {

        table {
            color: $channel-primary;

            td {
                padding: 18px 20px 10px 20px;
            }
        }

        tr th {
            font-family: $default-font-stack-regular;
            font-weight: normal;
            padding    : 14px 8px 8px 8px;
        }

        tr td:first-child {
            a {
                text-transform: uppercase;
                font-family   : $default-font-stack-regular;
                font-size     : 16px;

                &::before {
                    display: none;
                }
            }

            p {
                color        : $primary;
                margin-bottom: 0;
            }
        }
    }
}

.template-add-trouble-ticket {

    #form {
        margin: 20px 0 0 0 !important;
    }

    label {
        font-family: $default-font-stack-roman;
        font-weight: normal;
    }

    input,
    textarea {
        background-color: #f6f6f6;
        border          : none;
        border-radius   : 0;
        box-shadow      : none;
    }

    .formControls {
        padding: 0;
    }

    #form-buttons-send,
    #form-buttons-cancel {
        border       : 1px solid transparent;
        border-radius: 0;
        font-family  : $title-font-stack;
        font-size    : 12px;
        box-shadow   : none;
        text-shadow  : none;
        padding      : 10px 20px;
        font-weight  : normal;
    }

    #form-buttons-send {
        background-color: $channel-primary;
        color           : $channel-light;
        border          : 1px solid $channel-primary;
    }

    #form-buttons-cancel {
        border: 1px solid $channel-secondary;
    }

    .mce-top-part {
      display: none;
    }
}
