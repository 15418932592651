.template-full_view {
  #content {
    h2.headline {
      font-size: 18px;
      line-height: 26px;
      margin-top: 52px;
      margin-bottom: 0;
      font-weight: bold;
    }

    #plone-document-byline {
      margin: 0px;
    }
    .has-table {
      overflow: auto;
      margin-top: 10px;

      table {
        margin-bottom: 11px;

        td,
        th {
          vertical-align: middle;
        }

        th {
          font-family: $default-font-stack-regular;
          font-size: 14px;
          line-height: 20px;
          font-weight: normal;
        }

        td {
          vertical-align: middle;
          text-align: left;
          padding: 17px 20px;
          font-size: 14px;
          line-height: 20px;
          font-family: $default-font-stack;

          a {
            word-break: break-all;
            &:before {
              content: "";
              width: 0px;
              height: 0px;
              margin: 0px;
            }
          }
        }
      }
    }
  }
}
