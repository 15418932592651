.header {
  padding   : 2rem 1rem 0 1rem;
  background: #FFFFFF;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  position  : relative;
  z-index   : 11;
  min-height: 140px;

  .plone-toolbar-left-default & {
    &.fixed-top {
      // cancel fixed top on header when we have toolbar
      //position : relative;
      margin-left: 60px;

      @media (max-width: 767px) {
        margin-left: 0px;
      }
    }
  }

  .plone-toolbar-left-expanded & {
    &.fixed-top {

      @media (max-width: 767px) {
        margin-left: 0px;
      }
    }
  }

  // Removing default font icons
  .icon-user,
  .icon-search {
    &::before {
      content: none;
    }
  }

  .user-actions {
    position: relative;

    .dropdown-menu {
      min-width : 100%;
      z-index   : 1;
      display   : block;
      transition: transform .5s;
      transform : translateY(-100%);
      box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0);

      &::before {
        content         : " ";
        position        : absolute;
        bottom          : 100%;
        width           : 120%;
        height          : 100%;
        background-color: $channel-light;
        transition      : .5s;
        transform       : translateY(100%);
        margin-left     : -10%;
      }

      a {
        text-align : center;
        padding    : 10px;
        white-space: normal;
      }
    }

    &.open .dropdown-menu {
      transform : translateY(0%);
      box-shadow: 0 12px 10px 0 rgba(0, 0, 0, .08);

      &::before {
        transform: translateY(0%);
      }
    }
  }

  #user-name {
    margin     : 0 15px;
    line-height: 2.5rem;
    z-index    : 2;
    position   : relative;

    .chevron-down {
      display            : inline-block;
      width              : 1rem;
      transition-duration: 0.3s;
    }

    &[aria-expanded="true"] {
      .chevron-down {
        transform: rotate(180deg);
      }
    }

    &,
    &:hover,
    &:active,
    a:visited {
      text-decoration: none;
    }

    .username {
      margin     : 10px 15px 10px 5px;
      font-size  : 12px;
      font-family: $default-font-stack-roman;
      font-weight: normal;

      .caret {
        display: none;
      }
    }

    svg {
      g {
        transform: scale(1.3) translateX(-0.2) translateY(-O.5);
      }
    }
  }

  .logo-header {
    flex: 1;
  }

  .dropdown-menu {
    right    : 0;
    margin   : 0;
    font-size: 12px;

    a {
      padding       : 18px 10px 10px 10px;
      text-align    : center;
      font-family   : $default-font-stack-roman;
      font-size     : 12px;
      letter-spacing: 0.05rem;

      &:hover,
      &:focus {
        background-color: #000000;
        color           : #FFFFFF;
      }
    }
  }

  @media (max-width: 767px) {
    & {
      padding   : 15px;
      min-height: auto;
    }

    #user-name {
      padding-right: 15px;

      span *:not(.caret) {
        display: none;
      }

      .desktop {
        display: none;
      }
    }

    .user-actions {
      margin-right: 0;
    }

    &>div>div {
      padding: 0;
    }

    .dropdown-menu {
      left : auto;
      right: 0;
    }
  }

  a:hover,
  a:focus {
    color: #000;
  }
}

.row::before,
.row::after {
  display: none;
}

.navbar-brand {
  margin-bottom: 5px;
  margin-right : 0;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-nav li.index_html {
  display: none;
}

.template-contact-info,
.template-login,
.template-login-help {
  .header {
    min-height: auto;
    padding   : 2rem 1rem;
  }
}