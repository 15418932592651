.discussion {
  padding: 2rem 0;

  .comment {
    clear       : both;
    padding-left: 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .documentByLine {
    text-align : left;
    color      : #000;
    font-family: $default-font-stack-roman;
    font-size  : 16px;
  }

  .commentDate {
    float      : none !important;
    display    : block;
    color      : #000;
    font-family: $default-font-stack;
    font-size  : 12px;
    margin-top : 0.2rem;
  }

  .commentBody {
    background-color: #F6F6F6;
    border          : none;
    margin          : 1rem 0rem 1rem 2rem;
    text-align      : left;
    color           : #000;
    font-size       : 14px;
    font-family     : $default-font-stack;
  }

  .reply-to-comment-button {
    display: none;
  }
}

.commentActions {
  .destructive {
    width           : 18px;
    height          : 18px;
    text-indent     : -2000em;
    background-color: transparent;
    border          : none;
    padding         : 0;
    background-size : cover;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNDU5IDQ1OSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDU5IDQ1OTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJkZWxldGUiPgoJCTxwYXRoIGQ9Ik03Ni41LDQwOGMwLDI4LjA1LDIyLjk1LDUxLDUxLDUxaDIwNGMyOC4wNSwwLDUxLTIyLjk1LDUxLTUxVjEwMmgtMzA2VjQwOHogTTQwOCwyNS41aC04OS4yNUwyOTMuMjUsMGgtMTI3LjVsLTI1LjUsMjUuNSAgICBINTF2NTFoMzU3VjI1LjV6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==)
  }

  a.commentactionsform {
    display         : inline-block;
    width           : 18px;
    height          : 18px;
    text-indent     : -2000em;
    background-color: transparent;
    border          : none;
    padding         : 0;
    background-size : cover;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTI4Ljg5OSA1MjguODk5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MjguODk5IDUyOC44OTk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMzI4Ljg4Myw4OS4xMjVsMTA3LjU5LDEwNy41ODlsLTI3Mi4zNCwyNzIuMzRMNTYuNjA0LDM2MS40NjVMMzI4Ljg4Myw4OS4xMjV6IE01MTguMTEzLDYzLjE3N2wtNDcuOTgxLTQ3Ljk4MSAgIGMtMTguNTQzLTE4LjU0My00OC42NTMtMTguNTQzLTY3LjI1OSwwbC00NS45NjEsNDUuOTYxbDEwNy41OSwxMDcuNTlsNTMuNjExLTUzLjYxMSAgIEM1MzIuNDk1LDEwMC43NTMsNTMyLjQ5NSw3Ny41NTksNTE4LjExMyw2My4xNzd6IE0wLjMsNTEyLjY5Yy0xLjk1OCw4LjgxMiw1Ljk5OCwxNi43MDgsMTQuODExLDE0LjU2NWwxMTkuODkxLTI5LjA2OSAgIEwyNy40NzMsMzkwLjU5N0wwLjMsNTEyLjY5eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=)
  }
}

.context {
  background-color: #000;
  border-color    : transparent;

  &.reply-to-comment-button {
    float        : left;
    margin-bottom: 30px;
  }
}

#commenting,
.discussion {
  padding-right: 5px;

  legend {
    text-align: left;
  }

  .documentByLine {
    a {
      font-size: 16px;
    }
  }

  .commentImage {
    display: none;
  }
}

// Commenter

.form-widgets-comment-text {
  display: none;
}

#commenting {
  
  .discussion,#commenting.reply{
    padding-left: 1.2%;
    padding-right: 1.05%;
  }

  legend {
    font-family  : $default-font-stack-roman;
    font-size    : 1rem;
    border-bottom: none;
    margin-bottom: 0;
    padding      : 2rem 0 0 0;
  }

  #form-widgets-comment-text {
    background-color: #f6f6f6;
    border          : none;
    border-radius   : 0;
    box-shadow      : none;
  }

  p {
    text-align: left;
  }

  .formControls {
    background: transparent;
    border-top: none;
    padding   : 0;
    box-shadow: none;
    text-align: left;

    input {
      border       : none;
      border-radius: 0;
    }

    #form-buttons-comment {
      font-family: $title-font-stack;
      font-size  : 12px;
      padding    : 10px 20px;
      font-weight: normal;
    }
  }

  // hide comments label and description
  #formfield-form-widgets-comment-text label {
    display: none;
  }

  fieldset>p {
    display: none;
  }
}

#commenting-flowplayer{
  position: sticky;
  right: 100%;
}

.portaltype-file #content {
  #commenting {
    padding-right: 0;
  }

  &>#commenting {
    width : 75%;
    margin: 0 auto;
  }
}