.template-contact-info {

    #breadcrumb,
    .navbar {
        display: none;
    }

    #content-block {
        justify-content: center;

        @media (min-width: 992px) {
            #content {
                max-width: 90% !important;
            }
        }
    }

    #content {
        margin-top: 80px;
    }

    .documentDescription {
        color: #666;
    }

    form {
        label {
            margin-top: 10px;
        }

        textarea,
        input[type="text"] {
            background-color: #F5F5F5;
            border          : none;
            box-shadow      : none;
            border-radius   : 0;
        }

        [type="submit"] {
            border          : 1px solid transparent;
            border-radius   : 0;
            font-family     : $title-font-stack;
            font-size       : 12px;
            box-shadow      : none;
            text-shadow     : none;
            padding         : 10px 20px;
            font-weight     : normal;
            background-color: $channel-primary;
            color           : $channel-light;
            border          : 1px solid $channel-primary;
        }

        .formControls {
            padding: 0;
        }
    }
}