.template-flowplayer,
.template-big_thumbnails,
.template-small_thumbnails,
.template-twofold_view,
.template-facetednavigation_view,
.template-video_view {
    #content-core,
    #content {
        display        : flex;
        flex-direction : column;
        justify-content: space-between;
        align-items    : center;

        #thumbnails .card {
            padding: 0;
        }

        @media (min-width: 1600px) {
            #thumbnails .card {
                flex     : 0 0 19%;
                max-width: 19%;
            }
        }

        @media (min-width: 1200px) and (max-width: 1599px) {
            #thumbnails .card {
                flex     : 0 0 25%;
                max-width: 25%;
            }
        }
    }
}

.campaign-information {
    margin-top: 0;

    h3 {
        font-family  : $default-font-stack-regular;
        position     : relative;
        padding      : 0;
        margin       : 0;
        display      : inline-flex;
        align-items  : center;
        margin-bottom: 1.5rem;

        &::before {
            content            : "";
            background         : url(../img/info.svg) no-repeat;
            background-size    : 20px;
            background-position: center;
            display            : inline-flex;
            border-radius      : 50%;
            width              : 20px;
            height             : 20px;
            flex-shrink        : 0;
            flex-grow          : 0;
            flex-basis         : 20px;
            margin-right       : 0.5rem;
            margin-top         : -0.2rem;
        }
    }

    dt {
        font-weight   : bold;
        vertical-align: top;
    }
}

.campaign-information-button {
    display        : flex;
    align-items    : center;
    margin-bottom  : auto;
    opacity        : 1;
    padding        : 0.2rem 1rem;
    margin-left    : 1rem;
    border         : 1px solid transparent;
    font-family    : $default-font-stack-regular;
    font-size      : 12px;
    margin-bottom  : 1rem;
    justify-content: center;

    &:hover,
    &:focus,
    &:active {
        opacity        : 0.7;
        text-decoration: none;
    }

    .campaign-information-info {
        position: relative;
        padding : 0 1rem;

        &::before {
            content      : " ";
            display      : inline-block;
            border       : 2px solid $primary;
            border-radius: 50%;
            position     : absolute;
            width        : 1.6rem;
            height       : 1.6rem;
            top          : 50%;
            left         : 50%;
            transform    : translate(-50%, -55%);
        }
    }

    .campaign-information-text {
        text-align  : left;
        padding-left: 0.5rem;
    }
}

.downloads-button {
    .download-button+.modal {
        .modal-body ul {
            li a {
                word-break: break-all;
                ;
            }
        }
    }
}

.template-big_thumbnails {
    main #main-content {
        width: 100%;
    }

    &:not(.portlet-opened) {
        #breadcrumb {
            margin-left        : 1rem;
            transition-duration: 0.3;
            transition-delay   : 0.5s;
        }
    }

    &.portlet-opened {
        #breadcrumb {
            padding-left       : 0;
            margin-left        : 0;
            transition-duration: 0.3;
            transition-delay   : 0;
        }
    }

    #thumbnails {
        width: 100%;

        .entry-list.row {
            margin-left : 0;
            margin-right: 0;
            padding-left: 6px;
        }

        .folder-content {
            padding: 0px 6px 6px 0;

            .folder {
                height             : 100%;
                width              : 100%;
                overflow           : hidden;
                background-repeat  : no-repeat;
                background-size    : cover;
                background-position: 50%;
                box-shadow         : 2px 2px 5px 0 rgba(0, 0, 0, .4);
            }
        }
    }
}

.template-small_thumbnails,
.template-big_thumbnails {

    .modal-backdrop.show {
        opacity: 0;
    }

    #commenting {
        width: 100%;
    }
}

.template-facetednavigation_view #content-core #thumbnails .card,
.template-twofold_view #content-core #thumbnails .card,
.template-small_thumbnails #content #thumbnails .card {
    flex      : 0 0 18%;
    max-width : 18%;
    margin    : 1%;
    transition: all .2s;

    @media (min-width: 993px) and (max-width: 1200px) {
        & {
            flex     : 0 0 23%;
            max-width: 23%;
        }
    }

    @media (max-width: 992px) {
        & {
            flex     : 0 0 31%;
            max-width: 31%;
        }
    }

    @media (max-width: 768px) {
        & {
            flex     : 0 0 48%;
            max-width: 48%;
        }
    }

    @media (max-width: 480px) {
        & {
            flex     : 0 0 100%;
            max-width: 100%;
        }
    }

    &:hover,
    &:active {
        transform: scale(1.02);
        z-index  : 1;
        opacity  : 0.8;
    }
}

.template-flowplayer {
    #video-aside .section.clearfix {
        padding-bottom: 11rem;
    }

    .modal-dialog {
        max-width: 80vw;

        .modal-content .close:before {
            font-size: 37px;
        }

        .modal-body {
            .modal-description {
                margin: 40px 0 0 0;
            }

            .modal-title {
                font-family : $default-font-stack-bold-chanel;
                font-size: 16px;
            }

            ul {
                margin-block : 20px 40px;

                li:not(:first-child) {
                    .file-description {
                        margin-top: 2.5rem;
                    }
                }

                .file-description {
                    font-family  : $title-font-stack;
                    margin-bottom: 1.5rem;
                    font-size    : 14px;
                }

                h4 {
                    font-family: $title-font-stack;
                    font-size: 14px;
                }
                
                a {
                    font-family  : $default-font-stack;
                    font-size    : 12px;
                    margin-bottom: 0.4rem;
                    display      : inline-block;
                }
            }

            .modal-description+ul {
                margin: 0;
            }

            .download-all {
                margin-block-start: 23px;
                font-family: $title-font-stack;
                font-size: 14px;

                a:before {
                    content            : '';
                    width              : 30px;
                    height             : 30px;
                    display            : inline-flex;
                    padding-block-end: 8px;
                    background         : url(../img/download_invert.svg) no-repeat;
                    background-size    : 30px;
                    background-position: center;
                    flex-shrink        : 0;
                    flex-grow          : 0;
                    flex-basis         : 30px;
                }
            }
        }
    }
}
