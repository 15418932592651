.template-listing_view{

  #content{
    
    .documentByLine {
      display: none;
    }

    h1.documentFirstHeading{
      font-family: $title-font-stack;
      font-size: 12px;
      text-align: center;
      font-weight: normal;
    }
  
    #content-core{
  
      div.entries{
    
        article.entry{
          margin-bottom: 26px;
          
          
          span.summary{
            
            div.title-description{
              text-align: left;
            }


            display: flex;
            justify-content : space-between;
            align-items : center;
            @media (max-width: 768px){
              flex-direction : column;
              justify-content: center;
            }
            a{
              border-bottom-width: 0px;
  
            }
  
            a[class*="contenttype-"]{
      
              font-family: $default-font-stack-roman ;
              font-size: 16px;
              line-height: 25px;
              font-weight: normal;
              &::before{
                content:  "";
                width: 0px;
                margin-right: 0px;
              }
            }
      
            div.description,
            div.document_byline{
      
              font-family: $default-font-stack;
              font-size : 16px;
              line-height: 22px;
              font-weight: normal;
      
            }
            
            img{
              width : 179px;
              height : 107px;
              max-width: none;
              margin-left: 200px;
              object-fit: cover;
              @media (max-width: 768px){
                margin-left: 0px;
              }
            }
  
          }
        }
    
      }
  }

  }


}