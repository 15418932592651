.template-documentviewer {
    .documentFirstHeading {
        width       : 75% !important;
        margin-left : auto;
        margin-right: auto;
    }

    a,
    a+.discreet {
        font-size  : 12px;
        color      : $channel-primary;
        font-family: $default-font-stack;
    }

    .pat-documentviewer {
        width : 75% !important;
        margin: 20px auto;
    }

    .DV-fullscreenContainer {
        display: none;
    }
}
