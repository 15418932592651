#content-video {
  flex     : 1;
  max-width: 100%;

  &.aside-right {
    max-width: calc(100% - 20vw);
  }
}

#video-container {
  padding-left: 0;

  hr {
    border-color: transparent;
  }
}

#video-aside {
  display : block;
  position: relative;
}

#video-metadata-container {
  display: flex;

  #video-metadata-content {
    flex        : 1;
    text-align  : left;
    margin-right: 1rem;
  }

  #form-video-state {
    label {
      max-width: none;
    }
  }
}

.side-rich-description-video,
.main-rich-description-video {
  font-family: $default-font-stack;
  font-size  : 14px;
  text-align : left;
}

.main-rich-description-video {
  margin-top: 2rem;
}

// Video JS

.video-js {
  background-color: transparent !important;
  border          : 1px solid #cfcfcf !important;

  .vjs-control:focus {
    outline   : none;
    box-shadow: none;
  }

  .vjs-current-time {
    display: block !important;
  }

  .vjs-time-divider {
    display: block !important;
  }

  .vjs-duration {
    display: block !important;
  }

  .vjs-duration-display {
    display: block !important;
  }

  .vjs-tech {
    width : calc(100% + 2px) !important;
    height: calc(100% + 2px) !important;
    margin: -1px;
  }

  &.vjs-fluid,
  &.vjs-16-9,
  &.vjs-4-3 {
    overflow: hidden;
  }

  &.vjs-fullscreen {
    border: none !important;
  }
}

.vjs-poster {
  background-color: #fff !important;
  background-size : cover !important;
}

.vjs-text-track-display div {
  margin: 0 !important;
}

// Vue Flow player

#pos-download,
#internal-use {
  margin-bottom: 1rem;
}

.campaign-information,
.asset-information {
  margin-top: 2rem;
  text-align: left;

  h3 {
    font-family   : $default-font-stack;
    font-size     : rem(14);
    text-transform: uppercase;
    margin-bottom : 2rem;
  }

  dl {
    margin-bottom: 15px;
    display      : block;
  }

  dd,
  dt {
    font-family: $default-font-stack;
    font-size  : rem(14);
    display    : inline-block;
    font-weight: 400;
  }

  dt {
    width  : 130px;
    display: inline-block;
  }

  dd {
    width  : calc(100% - 140px);
    display: inline-block;
  }
}

.keywords {
  font-family: $default-font-stack;
  font-size  : rem(14);
  text-align : left;

  ul {
    &.list-diese {
      li {
        margin-right: 10px;

        &:before {
          content: "#";
        }
      }
    }
  }
}

.owl-carousel .owl-stage {
  padding-bottom: 10px;

  .owl-item {
    padding   : 10px;
    transition: all 0.2s;

    &:first-child:last-child {
      display: none;
    }

    &:hover {
      transform: scale(1.02);

      .card {
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
        opacity   : .8;
      }
    }
  }
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  font-size: 0;

  &::before {
    content            : "";
    background         : url(../img/prev.svg) no-repeat;
    background-size    : 20px;
    background-position: center;
    display            : block;
    width              : 10px;
    height             : 100%;
  }
}

.owl-carousel .owl-nav .owl-next {
  &::before {
    background-image: url(../img/next.svg);
  }
}

.owl-theme .owl-nav .disabled {
  opacity: 1 !important;

  &::before {
    opacity: 0.3 !important;
  }
}

.video-thumbnail {
  &.card {
    border-radius: 0;
    cursor       : pointer;
    height       : 100%;
    border       : none;
    box-shadow   : 0px 4px 10px 0 rgba(0, 0, 0, .1);

    .title {
      padding    : 10px;
      font-family: $default-font-stack;
      font-size  : rem(12);
      text-align : left;
    }

    .card-img-block {
      border: 1px solid #EBEBEB;
    }
  }
}

#video-aside {
  display       : flex;
  flex-direction: column;
  height        : auto;

  .section.clearfix,
  .download-button {
    margin-top: auto;
  }
}

.template-video_view {
  #video-richetext {
    padding: 0;
  }
}

.template-video_view #spinner-center {
  position        : fixed;
  top             : 0;
  left            : 0;
  background-color: rgba(0, 0, 0, 0.5);
  height          : 100%;
  width           : 100%;
  z-index         : 100;

  &>div {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 100%;

    .spinner-border {
      border            : .25em solid white;
      border-right-color: transparent;
      width             : 3rem;
      height            : 3rem;
    }
  }
}

@media (max-width: 991px) {
  #video-aside {
    height    : auto;
    margin-top: 2rem;
  }
}