.multi-widget {
    .elements {
        .element {
            padding-left  : 2rem;
            padding-top   : 1rem;
            padding-bottom: 1rem;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }

            div {
                margin-left : 0;
                width       : 100%;
                margin-right: 0;
            }

            .checkbox-widget,
            .fieldErrorBox:empty,
            &>label,
            .label,
            &.error::before,
            .fieldErrorBox {
                display: none;
            }

            .object-widget {
                .object-widget-field {
                    display       : flex;
                    flex-direction: column;
                    position      : relative;
                    padding-left  : 0;

                    &::before {
                        position: absolute;
                        right   : 0;
                    }

                    &.error input {
                        border-width: 2px;
                    }
                }
            }

            button {
                margin-bottom: 0;
            }
        }
    }
}