#portal-column-two {
    padding   : 0 1rem 0 2.5rem;
    width     : 20vw;

    &:empty {
        display: none;
    }
}

.portletStaticText {

    .portletHeader {
        font-size  : 12px;
        font-family: $title-font-stack;
        font-weight: normal;
    }

    .portletContent {
        padding    : 0.8rem;
        font-family: $default-font-stack;
        font-size  : 14px;
        line-height: 20px;
        color      : $channel-primary;
        font-weight: normal;

        p {
            color: $channel-primary;
        }
    }
}

.portletStaticText.omit-border {
    border       : none;
    border-radius: 0;
    box-shadow   : none;
    font-weight  : 700;
    margin-bottom: 30px;

    .portletHeader {
        background-color: transparent;
        border-radius   : 0;
        color           : $channel-primary;
    }

    .portletContent {
        border-top: 1px solid #cccccc;
        padding   : 0;

        p {
            padding: 0 0 1rem 0 !important;

            &:first-child {
                padding-top: 1.5rem !important;
            }

            a {
                display    : flex;
                padding    : 0;
                width      : 100%;
                font-family: $title-font-stack;
                text-align: start;
                
                &:hover,
                &:active,
                a:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

.portletStaticText.border {
    border-radius: 0;
    box-shadow   : 0 4px 10px 0 rgba(0, 0, 0, .1);
    border       : none !important;

    .portletHeader {
        color           : $channel-light;
        background-color: #C9C7C7;
        border-radius   : 0;
    }
}

.portletNavigationTree {
    text-align: left;
}