@font-face {
  font-family: 'ABChanel Corpo_Extra Light';
  src: url('../fonts/ABChanel_Corpo-Extra_Light_web.woff') format('woff'),
  url('../fonts/ABChanel_Corpo-Extra_Light_web.woff2') format('woff2'),
  url('../fonts/ABChanel_Corpo-Extra_Light_web.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABChanel Corpo_Light';
  src: url('../fonts/ABChanel_Corpo-Light_web.woff') format('woff'),
  url('../fonts/ABChanel_Corpo-Light_web.woff2') format('woff2'),
  url('../fonts/ABChanel_Corpo-Light_web.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABChanel Corpo_Regular';
  src: url('../fonts/ABChanel_Corpo-Regular_web.woff') format('woff'),
  url('../fonts/ABChanel_Corpo-Regular_web.woff2') format('woff2'),
  url('../fonts/ABChanel_Corpo-Regular_web.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABChanel Corpo_Semi Bold';
  src: url('../fonts/ABChanel_Corpo-Semi_Bold_web.woff') format('woff'),
  url('../fonts/ABChanel_Corpo-Semi_Bold_web.woff2') format('woff2'),
  url('../fonts/ABChanel_Corpo-Semi_Bold_web.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Std 57 Condensed';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/HelveticaNeueLTStd-Cn.woff') format('woff');
}


@font-face {
  font-family: 'Helvetica Neue LT Std 56 Italic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/HelveticaNeueLTStd-It.woff') format('woff');
}


@font-face {
  font-family: 'Helvetica Neue LT Std 47 Light Condensed';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/HelveticaNeueLTStd-LtCn.woff') format('woff');
}


@font-face {
  font-family: 'Helvetica Neue LT Std 45 Light';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/HelveticaNeueLTStd-Lt.woff') format('woff');
}


@font-face {
  font-family: 'Helvetica Neue LT Std 77 Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/HelveticaNeueLTStd-BdCn.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT Std 65 Medium';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/HelveticaNeueLTStd-Md.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT Std 55 Roman';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Helvetica-Neue-LT-Std-55-Roman_22526.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/HelveticaNeueBold.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT Std 75 Bold';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/HelveticaNeueBold.woff') format('woff');
}