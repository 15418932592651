// BO

.plone-toolbar-default {
  #content {
    text-align: left;
    margin    : 0 5% 0 5%;

    .documentFirstHeading {
      font-family: $default-font-stack;
      font-size  : rem(18px);
      font-weight: bold;
    }

    .documentByLine {
      padding-top: 10px;
    }

    #content-core {
      text-align: left;
    }
  }

  &.template-big_thumbnails {
    #content {
      text-align: left;
      margin    : 0 0 0 0;
    }
  }
}

.template-taxonomy-settings,
.template-taxonomy-edit-data {
  #content {
    text-align: left;

    label {
      vertical-align: baseline;
    }

    .taxonomy-tree {
      border : 1px groove;
      padding: 12px;
    }

    .react-pencil {
      min-width: 47%;
    }

    .tree-view_children{
      margin-left: 0;
    }

    .info {
      height         : auto;
      display        : flex;
      justify-content: flex-end;
      flex-direction : row;

      div:first-child {
        margin-right: auto;
      }
    }
  }
}

#content #content-core #form {
  text-align : left;
  margin-left: 12px;
}