.portaltype-troubleticket {

    #content-block {
        justify-content: center;

        @media (min-width: 992px) {
            #content {
                max-width: 70% !important;
            }
        }
    }


    h1 {
        font-family: $title-font-stack;
        font-size  : 14px;
        margin-top : 5em;
    }

    #folder-listing,
    #viewlet-below-content-title,
    #form-groups-settings,
    #formfield-form-widgets-trouble_description label .formHelp {
        display: none;
    }

    #commenting-troubleticket {
        margin-top: 20px;
    }

    .documentDescription {
        text-align: left;
        margin-top: 15px;
    }

    #formfield-form-widgets-trouble_date,
    #formfield-form-widgets-email {
        display        : flex;
        justify-content: center;

        label {
            margin-right: 5px;
        }
    }

    &.template-edit {

      .mce-top-part {
        display: none;
      }

    }
}
