#posplanning-toolbar {
    display: flex;
    flex-wrap: wrap;

    .title {
        display: none;
    }

    ul {
        display: flex;
        list-style: none;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        width: 100%;
        justify-content: space-around;

        li {
            flex: 1;
            border: 1px solid #e5e5e5;
            padding: 5px;
            padding-top: 10px;

            &:not(:first-child) {
                border-left: none;
            }
        }
    }
}

.section-pos-planning {
    h1.documentFirstHeading {
        font-size: 20px;
        margin-top: 25px;
        font-family: $default-font-stack-bold-chanel !important;
        text-align: left;
        border-bottom: 4px solid $channel-primary;
        padding-bottom: 5px;
        margin-bottom: 0px !important;
        display: flex;
        justify-content: space-between;

        img.toggle-all{
            padding: 6px;
            cursor: pointer;
            &.visible{
                transform: rotate(0deg);
                transition: transform 0.3s ease-in-out;
            }
            &.collapsed{
                transform: rotate(180deg);
                transition: transform 0.3s ease-in-out;
            }
        }
    }
    .documentByLine{
        padding-top:0px !important;
        margin-bottom: 0px !important;
    }
    .dashboard-tree {
        // margin-top: 10px;
        // margin-left: 10px;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                text-align: left;
                position: relative;

                li {
                    padding-left: 10px;
                }

                //yellow
                .no_prog_in_shop_sc,
                .all_reject_sc,
                .prog_ok_sc{
                    color: #F5C142;
                }

                //green
                .all_reject_no_sc,
                .prog_ok_no_sc{
                    color: #019618;
                }

                //grey
                .no_prog_in_shop_no_sc,
                .unknown{
                    color: rgba(0,0,0,0.53);
                }

                a{
                    border-bottom: none !important;
                }

                .link-warper{
                    padding: 5px 0;
                    width: 100%;
                    a{
                        border-bottom: none !important;
                    }
                }

                .level-2 {
                    border-bottom: 1px solid black !important;
                    margin-bottom: 8px;
                    padding-left: 21px;
                    padding-bottom: 0px;
                    display: block;

                    a{
                        text-transform: uppercase;
                        font-family: $default-font-stack-bold-chanel;
                        font-size: 18px;
                        width: unset;
                    }
                }

                .level-3 {
                    border-bottom: 1px solid #CBCBCB !important;
                    padding: 10px 0 2px 22px;
                    margin-bottom: 10px;
                    display: block;
                    a{
                        font-family: $default-font-stack-bolder;
                        font-weight: bold;
                        font-size: 16px;
                        width: unset;
                    }
                }

                .level-4{
                    padding-left: 25px;
                    a{

                        width: unset;
                        font-family: $default-font-stack-bolder;
                        font-size: 14px;
                        &::before {
                            content: " ";
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            // background-color: black;
                            margin-right: 5px;
                            margin-bottom: 1px;
                            background-color: #cccccc;
                        }
                    }
                }

                table {
                    margin-left: 20px;
                    margin-top: 5px;

                    tr {
                        &:nth-child(odd) {
                            background-color: #f6f6f6;
                        }

                        td {
                            border: none;
                            padding: 10px 10px 6px 10px;

                            input {
                                margin: 0;
                            }

                            a {
                                padding: 0;
                            }
                        }
                    }
                }

                .screen {
                    padding-left: 10px;


                    table {
                        margin-bottom: 20px;
                    }
                }

                &.carret-level-2{
                    >img{
                        position: absolute;
                        top: 4px;
                        left : -5px;
                    }
                }

                &.carret-level-3{
                    >img{
                        position: absolute;
                        top: 6px;
                        left : 4px;
                    }
                }

                &.carret-level-4{
                    >img{
                        position: absolute;
                        top: -1px;
                        left : 7px;
                    }
                }

                img.carret{
                    padding: 6px;
                    cursor: pointer;
                    &.visible{
                        transform: rotate(0deg);
                        transition: transform 0.3s ease-in-out;
                    }
                    &.collapsed{
                        transform: rotate(180deg);
                        transition: transform 0.3s ease-in-out;
                    }
                }

                .visible{
                    &+div{
                        &+ul,
                        &+div{
                            display: unset;
                        }
                    }
                }
                .collapsed{
                    &+div{
                        &+ul,
                        &+div{
                            display: none;
                        }
                    }
                }


            }
        }

        & > ul > li:not(:last-child) {
            padding-bottom: 10px;
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 15px;
        }
    }

    #screens {
        margin-top: 15px;

        div.screen {
            &:not(:last-child) {
                padding-bottom: 20px;
                border-bottom: 1px solid #e5e5e5;
                margin-bottom: 15px;
            }
        }
    }

    .card {
        flex: 0 0 calc(100% / 6);
        max-width: calc(100% / 6);
        padding: 0.5rem;

        @media (max-width: 1400px) {
            flex: 0 0 calc(100% / 5);
            max-width: calc(100% / 5);
        }

        @media (max-width: 1200px) {
            flex: 0 0 calc(100% / 4);
            max-width: calc(100% / 4);
        }

        @media (max-width: 992px) {
            flex: 0 0 calc(100% / 3);
            max-width: calc(100% / 3);
        }

        @media (max-width: 768px) {
            flex: 0 0 calc(100% / 2);
            max-width: calc(100% / 2);
        }

        @media (max-width: 480px) {
            flex: 0 0 calc(100%);
            max-width: calc(100%);
        }
    }

    .card-shadow {
        display: flex !important;
        flex-direction: column;
        position: relative;

        .card-link .card-img-link img.card-img-top{
            border-bottom  : 1px solid rgba(0, 0, 0, .1);
        }

        .programme-delete,
        .informations-icon {
            margin: 0.7rem;
            position: absolute;
        }

        .programme-delete {
            left: 0;
            z-index: 5;

            .icon {
                position: absolute;
                left: 0;
                pointer-events: none;
            }
            
            input.delete {
                border: 0;
                padding: 11px;
                height: 8px;

                &:hover {
                    border: 0 !important;
                }
            }
        }
        
        .informations {          
            &-text {
                position: absolute;
                display: none;
                padding: 43px 26px;
                background-color: #fff;
                inset: 0;
                z-index: 5;
                text-align: left;
                overflow-y: auto;
                font-size: 14px;

                &:hover {
                    display: block;
                }
            }

            &-icon{
                right: 0;
                top: 0;
                z-index: 6;


                &:hover+.informations-text {
                    display: block;
                }
            }
        }

    }

    .card-list {
        margin-bottom: 2rem;
        flex-flow: row;
        flex-wrap: wrap;
        .card {
            margin-bottom: 2rem;
        }
    }

    div.video-title {
        font-family: $navigation-font-stack;
        text-shadow: none;
        font-size: 12px;
        margin-top: 20px;
        color: #6d6d6d;
        padding: 0 2rem;
        white-space: normal;
        text-align: center;
        width: 100%;

        &::after {
            content: " ";
            display: block;
            width: 70%;
            margin: 15px auto 15px auto;
            border-bottom: 1px solid #cccccc;
        }
    }

    div.screen-title {
        text-align: left;
        text-transform: uppercase;
        display: list-item !important;
        list-style: disc;
        font-family: $default-font-stack;
        font-size: 14px;
        margin-left: 11px;
        margin-bottom: 14px;
    }

    div.programme-status {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        padding: 0 2rem;
        margin-top: auto;
        width: 100%;

        & > div {
            position: relative;
            width: calc(100% / 3);

            input {
                opacity: 0;
                z-index: 1;
                position: relative;
                padding: 0;
                width: 25px;
                overflow: hidden;

                & + div {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 0;

                    &::before {
                        content: " ";
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 25px;
                        height: 25px;
                        display: block;
                    }
                }

                &.accept {
                    & + div::before {
                        background-image: url(../img/CHECK.svg);
                    }
                }

                &.accept.active,
                &.accept:hover {
                    & + div::before {
                        background-image: url(../img/CHECK_CLIC.svg);
                    }
                }

                &.reject {
                    & + div::before {
                        background-image: url(../img/CROSS.svg);
                    }
                }

                &.reject.active,
                &.reject:hover {
                    & + div::before {
                        background-image: url(../img/CROSS_CLIC.svg);
                    }
                }

                &.reset {
                    & + div::before {
                        background-image: url(../img/RESET.svg);
                    }
                }
            }
        }
    }

    div.programme-dates {
        margin-top: 1rem;
        margin-bottom: 25px;
        padding: 0 2rem;
        width: 100%;

        fieldset {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.5rem;

            label {
                font-family: $default-font-stack-roman;
                font-weight: normal;
                font-size: 12px;
                margin: 0;
            }

            .programme-date {
                margin: 0 0 0 0.2rem;
                font-family: $default-font-stack;
                font-size: 12px;
                color: #6d6d6d;
            }

            .pattern-pickadate-wrapper {
                margin: 0 0 0 0.2rem;

                .pattern-pickadate-date-wrapper {
                    input {
                        width: auto;
                        margin: 0;
                        background-color: transparent;
                        border: none !important;
                        box-shadow: none !important;
                        padding: 0;
                        width: 100%;
                        text-align: right;
                        height: auto;
                    }
                }
            }
        }
    }



    div.programme-applied, div.special-request-applied, span.shop-applied {
        text-align: center;
        margin: -1rem 0 1rem 0;

        input {
            opacity: 0;
            height: 0;
            width: 0;

            & + label::before {
                content: " ";
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 25px;
                height: 25px;
                display: block;
                background-image: url(../img/CHECK.svg);
            }

            &:checked + label::before {
                background-image: url(../img/CHECK_CLIC.svg);
            }
        }
    }
    span.shop-applied{
        position: absolute;


        @-moz-document url-prefix() {
            margin-left:0px !important;
        }

        @media screen and (-webkit-min-device-pixel-ratio:0) {
            margin-left:7px;
        }

        input + label::before {
            background-image: url(../img/CHECK_SHOP.svg);
            width : 19px;
        }
        &:checked + label::before{
            width : 19px;
        }

    }

    .level-4.link-warper{
        span.shop-applied{
            top: 14px;
        }
    }

    .level-3.link-warper{
        span.shop-applied{
            top: 21px;
        }
    }

    .level-2.link-warper{
        span.shop-applied{
            top: 18px;
        }
    }

    div.special-request {
        .card-shadow {
            display: flex;
            flex-direction: column;
        }

        .comments {
            flex: 1;
            position: relative;
            overflow-y: auto;
            margin: 1rem;
            min-height: 150px;

            ul {
                position: absolute;
                top: 0;
                left: 0;
                list-style: none;
                width: 100%;
                padding: 0;
                text-align: left;

                li {
                    background-color: #f6f6f6;
                    color: #6d6d6d;
                    font-size: 14px;
                    padding: 0.5rem;
                    margin-bottom: 0.5rem;
                }
            }
        }

        .new-request {
            display: flex;
            position: relative;
            margin: 0 1rem 1rem 1rem;
            background-color: $channel-primary;

            input[type="text"] {
                background-color: #f9f9f9;
                border: 1px solid #f2f2f4;
                font-family: $default-font-stack-roman;
                border-radius: 0;
                box-shadow: none;
                padding-top: 9px;
            }

            input[type="button"] {
                background-color: transparent;
                color: transparent;
                padding: 0 5px;
                position: relative;
                z-index: 1;
            }

            &::before {
                background-image: url(../img/SEND_ARROW.svg);
                display: block;
                position: absolute;
                content: " ";
                width: 20px;
                height: 18px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                top: 50%;
                transform: translate(50%, -50%);
                right: 16px;
            }
        }
    }

    .programme-actions {
        margin-top: 2rem;

        input {
            font-family: $navigation-font-stack;
            font-size: 12px;
            padding: 12px 21px;
            border: none !important;
        }

        input.submit {
            background-color: $channel-primary;
            color: $channel-light;

            &:hover,
            &:focus,
            &:active {
                background-color: #d8d8d8;
            }

            &[disabled="disabled"] {
                background-color: #f5f5f5;
                color: #6d6d6d;
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        input.reset {
            background-color: #f5f5f5;
            color: #6d6d6d;
        }
    }

    .posplanning-listing {
        a.button {
            font-family: $title-font-stack;
            font-size: 12px;
            background-color: $primary;
            color: $channel-light;
            padding: 10px 20px;
            align-self: center;
            margin-top: 12px;
            display: inline-block;
        }

        .form-inline {
            flex-direction: column;

            .row {
                width: 100%;
            }
        }
    }

    .modal-dialog {
        .video-js {
            max-width: 100%;
        }
    }
}

.section-pos-planning .tree ul li table tr.programme {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.section-pos-planning.userrole-manager .tree ul li table tr.programme {
    display: grid;
    grid-template-columns: 20px 1fr 1fr 1fr;
}

.section-pos-planning:not(.userrole-manager) {
    .dashboard-tree {

        // ul li {
        //     .visible{
        //         &+a{
        //             &+ul,
        //             &+div{
        //                 display: unset;
        //             }
        //         }
        //     }
        //     .hidden{
        //         &+a{
        //             &+ul,
        //             &+div{
        //                 display: none;
        //             }
        //         }
        //     }
        // }

        .screen {
            .card-list {
                margin-bottom: 2rem;
                flex-flow: column;
                .card {
                    margin-bottom: 0.5rem;
                    flex:unset;
                    padding: 0;
                    max-width: 100%;

                }
                .programme{
                    height: 67px;
                }
            }
            .card-shadow{
                flex-direction: row;
                width: 100%;
                align-items: center;
                border-radius: 0px !important;
                border: 1px solid #CBCBCB;
                box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                justify-content:space-between;

                .card-link{
                    padding: 0px !important;
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 0px !important;
                    img{
                        display: none;
                    }
                }
            }

            div.video-title {
                margin-top: 0px;
                text-align: start;

                &::after {
                    content: "";
                    display: absolute;
                    width: unset;
                    margin: 0px;
                    border-bottom-width: 0px;
                }
            }

            div.programme-dates{
                width: 29%;
                display:flex;
                margin-top: 0px;
                margin-bottom: 0px;
                fieldset {
                    width:50%;
                    .programme-date {
                        margin: 0;
                        color: #6D6D6D;
                        width: 100%;
                        text-align: center;
                        margin: 0px 19px;
                        height: 27px;
                        background-color: #F6F6F6;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }


            }

            div.special-request{
                min-height: 81px;
                max-height: 132px;

                .card-shadow{
                    border-width: 0px !important;
                    height:100%;
                }
                .comments{
                    flex:unset;
                    min-height: unset;
                    max-height: 100px;
                    width:70%;
                    margin-right: 51px;

                    ul{
                        position:relative;
                    }
                    // &.two-sr{
                    //     overflow-y: unset;
                    // }
                }
            }
        }
    }
}

#modal-popup-messages {
    background-color: transparent;
    .modal-dialog {
        $min-width: 800px;
        min-width: 100%;
        @media (min-width: $min-width) {
            min-width: $min-width;
        }
    }

    .modal-content {
        box-shadow: 0 0 32px -3px rgba(0,0,0,0.25);
        border: 0;

        .modal-footer, .modal-body {
            padding-inline-start: 42px;
        }
        .modal-footer {
            padding-inline-end: 34px;
            
            #dismiss-further-messages {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;
            }

            .do-not-display{
                font-size: 12px;
                line-height: 14.65px;
                position: relative;
                display: block;
                padding-left: 35px;
                max-width: none;
                cursor: pointer;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    margin-top: -13px;
                    left: 0;
                    display: block;
                    height: 20px;
                    width: 20px;
                    background-color: transparent;
                    border-radius: 6px;
                    border: 2px solid #4C4C4C;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 8px;
                    top: -1px;
                    width: 5px;
                    height: 10px;
                    border: solid #4C4C4C;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    opacity: 0;
                }
            }

            #dismiss-further-messages:checked + .do-not-display{
                &:before {
                    background-color: #AFE6A2;
                }
                &:after {
                    opacity: 1;
                }
            }

            .i-understand {
                background-color: black;
                border: 0;
                border-radius: 10px;
                padding-block-end: 9px;
                padding-block-start: 9px;
                padding-inline: 40px;
                color: white;
                font-size: 13px;
                span {
                    font-family: "Helvetica Neue Bold", Helvetica, sans-serif;
                }
            }
        }
        
        .modal-body {
            padding-inline-end: 92px;
            padding-block: 36px;

            #popup-messages{
                & > * {
                    text-align: left;
                    font-size: 15px;
                    font-weight: 500;
                    margin-block-end: 0.5rem;
                }
            }
            .close {
                right: 38px;
                top: 34px;

                &:before {
                    content: "";
                }
            }
        }

        .modal-footer {
            border: 0;

            .row {
                width: 100%;
                justify-content: space-between;
                align-items: baseline;
            }
        }
    }
}