.entry-list {
  a {
    display        : block;
    text-decoration: none;
    color          : inherit;
  }
}

.image-block {
  width              : 33.33%;
  display            : inline-flex;
  height             : 420px;
  overflow           : hidden;
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center center;

  &:nth-child(n+6) {}

  .content {
    margin             : 2rem;
    background-color   : #fff;
    text-align         : left;
    padding            : 0.5rem 0.5rem 0;
    min-width          : 10%;
    max-width          : 50%;
    border             : 1px solid #E5E5E5;
    transition         : .5s;
    position           : relative;
    box-shadow         : 3px 5px 5px 0 rgba(0, 0, 0, 0.1);
    transition-property: height min-width;
    transition-duration: 0.5s;

    .description {
      height             : 0;
      width              : 0;
      overflow           : hidden;
      transition-property: height width;
      transition-duration: 0.8s;
      font-size          : 12px;

      &::before {
        content: " ";
        height : 1rem;
        display: block;
      }
    }

    h3 {
      font-size  : rem(12);
      cursor     : pointer;
      font-family: $navigation-font-stack;
    }

    p {
      font-size  : rem(12);
      font-family: $default-font-stack;
    }
  }

  &:hover {
    .content {
      min-width          : 40%;
      max-width          : 50%;
      height             : auto;
      transition-property: height min-width;
      transition-duration: 0.5s;
    }

    .description:not(:empty) {
      width              : auto;
      height             : 120px;
      transition-property: height width;
      transition-duration: 0.5s;

      &::after {
        content   : " ";
        display   : block;
        width     : 100%;
        height    : 40px;
        background: linear-gradient(transparent, white 50%);
        position  : absolute;
        bottom    : 0;
        left      : 0;
      }
    }
  }
}

.template-folder-card {
  .container-fluid {}
}

.card-list {
  justify-content: flex-start !important;

  &.row {
    margin-left : -10px;
    margin-right: -10px;
  }

  .card {
    border: none;
  }

  .card-body {
    padding    : 16px 2.5rem 12px 12px;
    text-align : left;
    font-family: $default-font-stack;
    position   : relative;
  }

  .pastille-validation {
    height          : 24px;
    width           : 24px;
    background-color: #CCCCCC;
    display         : block;
    position        : absolute;
    right           : 0.5rem;
    top             : 8px;
    border-radius   : 50%;

    &.validated {
      background-color: #2B5A36;
    }

    &.rejected {
      background-color: #8E000D;
    }
  }
}

.card {

  h3 {
    font-size     : rem(12);
    text-transform: uppercase;
    margin-bottom : 0;
    font-family   : $default-font-stack-regular;
  }

  p {
    font-size    : rem(12);
    margin-bottom: 0;

    &:not(:empty) {
      margin-top: 0.5em;
    }
  }
}

// Card

.card-shadow {
  box-shadow                : 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  margin-bottom             : 0 !important;
  border-bottom-left-radius : 5px;
  border-bottom-right-radius: 5px;

  .card-link {
    margin-bottom: auto;
  }

  &>a:not(.card-link),
  &>button .card-button-image {
    width         : 100%;
    padding-bottom: 60% !important;
    display       : inline-block;
    position      : relative;
    overflow      : hidden;
  }

  &>button {

    &,
    &:hover,
    &:active,
    &:focus {
      width           : 100%;
      padding         : 0;
      background-color: transparent;
      box-shadow      : none;
      border          : none;
    }
  }

  .card & {
    height       : 100%;
    margin-bottom: 30px;
  }

  .card-img-content {
    position: absolute;
    width   : 100%;
    height  : 100%;
    top     : 0;
    left    : 0;

    &::after {
      content : " ";
      display : block;
      width   : 100%;
      height  : 100%;
      position: absolute;
      top     : 0;
      left    : 0;
      border  : 1px solid rgba(0, 0, 0, .1);
    }

    .card-img-top {
      object-fit   : cover;
      min-width    : 100%;
      min-height   : 100%;
      border-radius: 0;
    }
  }
}

.owl-carousel .owl-stage {
  display: flex;

  .owl-item {
    display   : inline-block;
    min-height: 100%;
    float     : none;

    .card {
      border-bottom-left-radius : 5px;
      border-bottom-right-radius: 5px;
    }

    .card-img-block {
      width         : 100%;
      padding-bottom: 60%;
      position      : relative;

      .card-img-top {
        width        : 100%;
        height       : 100%;
        position     : absolute;
        top          : 0;
        left         : 0;
        object-fit   : cover;
        border-radius: 0;
      }
    }
  }
}

// Collection

.portaltype-collection {

  [class*=contenttype-] {

    &::before {
      display: none;
    }
  }

  img[class*=thumb-] {
    border: 1px solid #e5e5e5;
  }
}