.template-twofold_view {
  header {
    width: 100%;
  }

  #content-core {
    width: 100%;

    #content-twofold {
      @media (max-width: 480px) {
        width: 80%;
      }
      width: 100%;
      .folder {
        margin: 42px 0 59px;

        h2.headline {
          margin-bottom: 0px;

          a {
            font-size: 23px;
          }
        }

        div.description {
          margin-top: 2px;
        }

        section.content-gallery {
          margin-top: 5px;

          #thumbnails {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
