.template-facetednavigation_view {
  #content-core {
    width: 100%;

    & > div {
      width: 100%;
    }
  }

  div.faceted-area.left-area {
    float: none;
    width: auto;
    padding: 2.5rem 1.5rem 0 2.5rem;
  }

  #faceted-form div.faceted-area.center-area {
    .left-area-js {
      margin-left: 0;

      fieldset.widget-fieldset {
        margin: 0;
        padding: 0;
        border: none;

        .widget-body {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        legend {
          display: none;
        }

        dl {
          display: inline-flex;
          flex-wrap: wrap;
          font-weight: normal;
          line-height: normal;
          margin-bottom: 0;
        }

        dd,
        dt {
          margin: 0;
        }

        dt,
        dd > span {
          display: inline-flex;
          flex-direction: row-reverse;
          align-items: center;
          margin-right: 0.5rem;
          font-weight: normal;
          line-height: normal;

          span {
            padding-right: 0.2em;
          }
        }

        dd > span {
          margin-top: 4px;
        }
      }
    }
  }

  .select2-drop {
    color: #6d6d6d;
  }

  .select2-drop-active {
    border: 1px solid #f6f6f6;
    margin-top: 0.5rem;

    .select2-results {
      margin: 0;
      padding: 0;

      .select2-result-label,
      .select2-no-results {
        padding: 10px 4px 4px 4px;
      }

      .select2-highlighted {
        background-color: #f6f6f6;
        color: $channel-primary;
      }
    }
  }

  .select2-container-multi {
    .select2-choices {
      border: none !important;
      box-shadow: none !important;
      background-color: transparent;
      background-image: none;
      // margin-top: 10px;

      .select2-search-field {
        border: 1px solid #e8e8e8 !important;
        background-color: #fcfcfc;
        box-shadow: none !important;
        background-image: none;
        min-height: 32px;
        grid-column: span 2;
        position: relative;

        input {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100%;
          border: none !important;
        }
      }

      .select2-search-choice {
        background-color: #f6f6f6 !important;
        color: #6d6d6d;
        padding: 0;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin: 0;
        border-radius: 6px;

        &:first-child:nth-last-child(2),
        &:nth-child(odd):nth-last-child(2) {
          grid-column: span 2;
        }

        div {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1.1rem 0.5rem 0.7rem 0.5rem;
          font-size: 12px;

          &::before {
            margin-bottom: 4px;
            margin-right: 5px;
          }
        }

        .select2-search-choice-close {
          height: 100%;
          width: 100%;
          background-image: none !important;
          top: 0;
          left: 0;
        }
      }
    }
  }

  fieldset.widget-fieldset {
    padding: 0 0 2rem;

    legend {
      font-family: $default-font-stack-bold-chanel;
      font-size: rem(13);
      text-align: left;
      padding: 0;
      margin: 0;
      position: relative;
      border: none;
      margin-bottom: 0;
      cursor: pointer;
      font-weight: 400;

      &::after {
        content: "";
        background: url("../img/dropdown-caret.svg") no-repeat center center;
        border: none;
        width: 15px;
        height: 15px;
        transition: 0.3s;
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        transition: 0.3s;
      }
    }

    form {
      transition: height 0.35s ease;
      overflow: hidden;
      margin-top: 10px;
      position: relative;

      ul {
        padding: 0;
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5rem;

        li {
          div,
          label {
            font-family: $default-font-stack-light-chanel;
          }

          label {
            background-color: #f6f6f6;
            color: #6d6d6d;
            width: 100%;
            padding: 0;
            margin: 0;
            max-width: 100%;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem 0;
            height: 100%;
            font-size: 12px;
            line-height: 14px;
            overflow-wrap: anywhere;
            padding: 1rem 0.5rem 0.8rem 0.5rem;
            cursor: pointer;
          }

          input {
            display: none;

            &:checked + label {
              background-color: #d8d8d8;
              color: $channel-light;
            }
          }

          &:nth-child(odd):last-child {
            grid-column: span 2;
          }
        }
      }

      input[type="text"] {
        border: 1px solid #e8e8e8 !important;
        background-color: #fcfcfc;
      }

      .searchButton {
        position: absolute;
        top: 0;
        right: 2px;
        height: 34px;
        width: 34px;
        background-image: url(../img/loupe.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: #d8d8d8;
        border: 0px !important;
      }
    }

    &.display {
      legend {
        &::after {
          transform: rotate(-180deg);
        }
      }
    }

    &:not(.display) {
      form {
        height: 0;
      }
    }
  }

  .section-status {
    li[id*="published"],
    li[id*="private"] {
      display: none;
    }

    li[id*="_validated"] {
      grid-row: 1;
    }
  }

  .section-axis {
    li[id*="LED"],
    li[id*="Led"] {
      display: none;
    }
  }

  //.select2-search-choice div,
  .faceted-criteria-reset a,
  .faceted-remove {
    &::before {
      background-image: url(../img/close.svg);
      background-size: contain;
      height: 10px;
      width: 10px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      content: "";
      margin-bottom: 2px;
    }
  }

  .faceted-criteria-hide-show {
    display: none;
  }

  .faceted-criteria-reset {
    display: inline;

    a {
      color: rgb(33, 37, 41) !important;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      &::before {
        margin-left: 5px;
      }
    }
  }

  #portlet-burger{
    
    display : grid;
    grid-template-columns : 20vw 20vw;
    @media (max-width: 768px){
      grid-template-columns : 100vw 100vw;
    }

    &>*{
      transition: transform 0.5s;
    }

    &>.nav.active,
    &>#left-area:not(.active){
      transform: translateX(0);
    }

    &>.nav:not(.active),
    &>#left-area.active{
      transform: translateX(-20vw);
      @media (max-width: 768px){
      transform: translateX(-100vw);
        
      }
    }

    & > aside{
      display: none;
    }



  }

}
